// AuthContext.js
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";

const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const [token, setTokenState] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [authIsLoading, setAuthIsLoading] = useState(true);

  const setToken = useCallback((newToken) => {
    if (typeof newToken !== "string" && newToken !== null) {
      throw new Error("Token must be a string or null");
    }
    if (newToken === null) {
      localStorage.removeItem("token");
    } else {
      localStorage.setItem("token", newToken);
    }
    setTokenState(newToken);
  }, []);

  const reRenderToken = useCallback(() => {
    setTokenState(null); // Temporarily set token to null
    setTimeout(() => {
      setTokenState((prevToken) => localStorage.getItem("token")); // Re-set the token after a short delay
    }, 0); // Use a delay of 0 to ensure React processes the state change
  }, []);

  useEffect(() => {
    let savedToken = localStorage.getItem("token");
    if (savedToken === "null") {
      savedToken = null;
    }
    if (savedToken || savedToken === null) {
      setToken(savedToken);
    }
    setAuthIsLoading(false);
  }, [setToken]);

  // Auth-specific methods
  const login = async (credentials) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(credentials),
      });

      const data = await response.json();

      if (data.token) {
        setToken(data.token);
        setAuthError(null);
        // Optional: Store token in secure storage
        return { success: true, user: data.user };
      } else if (!response.ok && data?.message) {
        setAuthError(data.message);
        return { success: false };
      } else {
        setAuthError("Login mislykkede");
        return { success: false };
      }
    } catch (error) {
      console.log(error);
      setAuthError(error.message);
      return { success: false, error: error.message };
    }
  };

  const logout = useCallback(() => {
    setToken(null);
  }, [setToken]);

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        reRenderToken,
        authError,
        login,
        logout,
        authIsLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
