// src/pages/Settings.js
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { usePermissions } from "../contexts/PermissionsContext";
import { useRoles } from "../contexts/RolesContext"; // Assuming you have a similar context for roles
import { useAuthContext } from "../contexts/AuthContext";
import { useAuth } from "../hooks/useAuth";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

import {
  Container,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Box,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
} from "@mui/material";

import { AccountBalanceWallet, Storage } from "@mui/icons-material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useUserContext } from "../contexts/UserContext";
import { useCompanyContext } from "../contexts/CompanyContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import logoNoText from "../logo-no-text.png";
import CloseIcon from "@mui/icons-material/Close";

const CompanyMemberTable = () => {
  const { token } = useAuthContext();
  const { activeCompanyId } = useCompanyContext();
  const { showSnackbar } = useSnackbar();
  const { userData } = useUserContext();

  const Toolbar = React.memo(() => (
    <Box sx={{ display: "flex", p: 0.5, pb: 0, alignItems: "center" }}>
      <GridToolbarQuickFilter />
    </Box>
  ));

  const timeAgo = (date) => {
    if (!date) return "N/A";

    const now = new Date();
    const past = new Date(date);
    const diffInSeconds = Math.floor((now - past) / 1000);

    const secondsInMinute = 60;
    const secondsInHour = secondsInMinute * 60;
    const secondsInDay = secondsInHour * 24;
    const secondsInWeek = secondsInDay * 7;
    const secondsInMonth = secondsInDay * 30; // Approximation
    const secondsInYear = secondsInDay * 365; // Approximation

    if (diffInSeconds < secondsInMinute) {
      return `${diffInSeconds} sekund${diffInSeconds > 1 ? "er" : ""} siden`;
    } else if (diffInSeconds < secondsInHour) {
      const minutes = Math.floor(diffInSeconds / secondsInMinute);
      return `${minutes} minut${minutes > 1 ? "ter" : ""} siden`;
    } else if (diffInSeconds < secondsInDay) {
      const hours = Math.floor(diffInSeconds / secondsInHour);
      return `${hours} time${hours > 1 ? "r" : ""} siden`;
    } else if (diffInSeconds < secondsInWeek) {
      const days = Math.floor(diffInSeconds / secondsInDay);
      return `${days} dag${days > 1 ? "e" : ""} siden`;
    } else if (diffInSeconds < secondsInMonth) {
      const weeks = Math.floor(diffInSeconds / secondsInWeek);
      return `${weeks} uge${weeks > 1 ? "r" : ""} siden`;
    } else if (diffInSeconds < secondsInYear) {
      const months = Math.floor(diffInSeconds / secondsInMonth);
      return `${months} måned${months > 1 ? "er" : ""} siden`;
    } else {
      const years = Math.floor(diffInSeconds / secondsInYear);
      return `${years} år siden`;
    }
  };

  const [companyUsers, setCompanyUsers] = useState([]);
  const [loadingUsersTable, setLoadingUsersTable] = useState(false);
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false);

  const handleCloseDialog = useCallback(() => {
    setOpenAddUserDialog(false);
  }, []);

  const handleDeleteClick = async (id) => {
    if (id === userData?.id) {
      return;
    }

    // Ask for confirmation
    const isConfirmed = window.confirm(
      `Er du sikker på, at du vil fjerne ${
        companyUsers.find((e) => e.id === id)?.email
      }'s adgang til denne virksomhed?`
    );

    if (isConfirmed) {
      try {
        setLoadingUsersTable(true);

        const deleteResponse = await fetch(
          process.env.REACT_APP_API_URL + "/me/company/users/remove",
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              remove_id: id,
              company_id: activeCompanyId,
            }),
          }
        );

        if (deleteResponse) {
          const newUsersData = await deleteResponse.json();
          setCompanyUsers(newUsersData.users);
        }
      } catch (error) {
        console.error(error);
        showSnackbar(
          `Der skete en fejl ved fjernelse af bruger med mail ${
            companyUsers.find((e) => e.id === id)?.email
          }`
        );
      } finally {
        setLoadingUsersTable(false);
      }
    }
  };

  useEffect(() => {
    if (!token || !activeCompanyId) return;

    const fetchCompanyUsers = async () => {
      try {
        setLoadingUsersTable(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/me/company/users?company_id=${activeCompanyId}`,
          {
            headers: {
              "Content-Type": "application/json", // Fixed header
              Authorization: `Bearer ${token}`, // Example: Include token if required
            },
          }
        );
        const usersResponse = await response.json();
        setCompanyUsers(usersResponse.users);
      } catch (err) {
        console.error("Failed to fetch company users:", err);
        // Optionally, set an error state here to display an error message
      } finally {
        setLoadingUsersTable(false);
      }
    };

    fetchCompanyUsers();
  }, [token, activeCompanyId]);

  const rows = useMemo(() => {
    return companyUsers.map((user) => ({
      id: user.id,
      name: user.name,
      email: user.email,
      level: "Bruger",
      joined: timeAgo(user?.pivot?.created_at),
    }));
  }, [companyUsers]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
    },
    {
      field: "name",
      headerName: "Navn",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "level",
      headerName: "Niveau",
      flex: 1,
    },
    {
      field: "joined",
      headerName: "Tilslutningsdato",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Handlinger",
      renderCell: (params) => {
        if (userData?.id === params.id) {
          return <></>;
        }
        return (
          <IconButton onClick={() => handleDeleteClick(params.id)}>
            <DeleteIcon />
          </IconButton>
        );
      },
      sortable: false,
      filterable: false,
      width: 100,
    },
  ];

  const AddUserDialog = React.memo(({ open, onClose, companyUsers }) => {
    const theme = useTheme();
    const [addMail, setAddMail] = useState("");
    const [emailError, setEmailError] = useState(""); // Email validation error state
    const [loadingAdd, setLoadingAdd] = useState(false); // Loading state for button
    const { showSnackbar } = useSnackbar();
    const { companyData, activeCompanyId } = useCompanyContext();
    const { token } = useAuthContext();

    const handleAddUsers = async () => {
      // Basic email validation regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (companyUsers.find((e) => e.email === addMail)) {
        setEmailError("Emailadresse er allerede tilknyttet.");
        return;
      }

      if (!emailRegex.test(addMail)) {
        setEmailError("Indtast en gyldig emailadresse.");
        return;
      }

      try {
        setLoadingAdd(true);
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/me/company/users/invite",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json", // Fix capitalization
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              invite: addMail,
              company_id: activeCompanyId,
            }),
          }
        );

        if (!response.ok) {
          if (response.status === 409) {
            const errorData = await response.json();
            showSnackbar(errorData.error);
          } else {
            throw new Error(
              "An error occured inviting user",
              response.statusText,
              response.status
            );
          }
        } else {
          showSnackbar(
            `${addMail} er blevet inviteret til at deltage i ${companyData?.name}. En e-mail er sendt med yderligere instruktioner.`,
            "info"
          );
        }
      } catch (error) {
        console.error(error);
        showSnackbar(
          "Der opstod en fejl ved invitation. Prøv igen senere eller kontakt support, hvis problemet fortsætter.",
          "error"
        );
      } finally {
        setAddMail("");
        setLoadingAdd(false);
        onClose();
      }
    };

    return (
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            borderRadius: theme.shape.borderRadius,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          },
        }}
      >
        <DialogTitle
          sx={{
            padding: 0,
            margin: 0,
            paddingBottom: "25px",
            width: "100%",
            textAlign: "center",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "15px",
              paddingRight: "75px",
              paddingLeft: "75px",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: theme.palette.background.paper,
              borderBottom: "1px solid #B0B0B0",
              gap: "10px",
            }}
          >
            <img
              src={logoNoText}
              alt={process.env.REACT_APP_COMPANY_NAME}
              style={{
                maxWidth: "100%",
                height: "auto",
                maxHeight: "50px",
              }}
            />
            Tilføj bruger
          </Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            padding: "50px",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            gap: "20px", // Reduced gap for better layout
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault(); // Prevent default form submission behavior
              handleAddUsers();
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="add-email"
              label="Email adresse"
              name="add-email"
              value={addMail}
              error={!!emailError} // Display error state if email is invalid
              helperText={emailError} // Error message below the input
              disabled={loadingAdd}
              onChange={(e) => {
                setAddMail(e.target.value);
                if (emailError) setEmailError(""); // Clear error on input change
              }}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ width: "50%", alignSelf: "center" }}
              disabled={loadingAdd || !addMail.trim()} // Disable if loading or input is empty
            >
              {loadingAdd ? "Tilføjer..." : "Tilføj"}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    );
  });

  return (
    <>
      <Box
        sx={{
          width: "100%",
          marginTop: 5,
          gap: 4,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h5" component="div">
          Selskabets brugere
        </Typography>

        <Button
          variant="outlined"
          color="primary"
          sx={{
            alignSelf: "flex-start",
            width: "auto",
          }}
          onClick={() => {
            setOpenAddUserDialog(true);
          }}
        >
          Tilføj bruger
        </Button>

        <DataGrid
          loading={loadingUsersTable}
          sx={{
            height: 300,
            width: "100%",
          }}
          rows={rows}
          columns={columns}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          slots={{ toolbar: Toolbar }}
        />
      </Box>
      <AddUserDialog
        open={openAddUserDialog}
        onClose={handleCloseDialog}
        companyUsers={companyUsers}
        setCompanyUsers={setCompanyUsers}
      />
    </>
  );
};

const Settings = ({ isDataModified, setIsDataModified }) => {
  const { token } = useAuthContext();
  const { isAuthenticated, authenticationIsLoading } = useAuth();

  const [banks, setBanks] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const { userData, updateUserData } = useUserContext();
  const { companyData, setCompanyData, activeCompanyId } = useCompanyContext();
  const [userDataChanges, setUserDataChanges] = useState({});
  const [companyDataChanges, setCompanyDataChanges] = useState({});
  const [passwordConfirmError, setPasswordConfirmError] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [showCurrentPassword, setCurrentShowPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [portalLoading, setPortalLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  const [passwordDataChanges, setPasswordDataChanges] = useState({
    password: "",
    new_password: "",
    confirm_password: "",
  });

  const permissions = usePermissions();
  const roles = useRoles();
  const environment = process.env.REACT_APP_ENVIRONMENT;

  const isSysAdmin = roles.includes("sys_admin");
  const hasPartialDbSyncPermission = permissions.includes("partial_db_sync");
  const [isPartialDbSyncing, setIsPartialDbSyncing] = useState(false);

  const tabs = [
    { label: "Profil", condition: true },
    { label: "Virksomhed", condition: true },
    { label: "Adgangskode", condition: true },
    {
      label: "Fakturering & Abonnement",
      condition: true,
    },
    {
      label: "Demo konto",
      condition: companyData?.has_access_to_demo && companyData?.has_access,
    },
    {
      label: "System administration",
      condition: isSysAdmin,
    },
  ];

  // Filter visible tabs
  const visibleTabs = tabs.filter((tab) => tab.condition);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/bankvendors", {
      headers: {
        Content: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setBanks(data);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIsDataModified(true);
    if (tabs[tabIndex]?.label === "Profil") {
      setUserDataChanges((prevUserDataChanges) => ({
        ...prevUserDataChanges,
        [name]: value,
      }));
    } else if (tabs[tabIndex]?.label === "Virksomhed") {
      setCompanyDataChanges((prevCompanyDataChanges) => ({
        ...prevCompanyDataChanges,
        [name]: value,
      }));
    } else if (tabs[tabIndex]?.label === "Adgangskode") {
      setPasswordDataChanges((prevPasswordDataChanges) => ({
        ...prevPasswordDataChanges,
        [name]: value,
      }));
    }
  };

  const handleTabChange = (event, newValue) => {
    if (isDataModified) {
      if (
        window.confirm(
          "Du har ændringer, som ikke er gemt. Er du sikker på, at du vil forlade siden?"
        )
      ) {
        setError("");
        setSuccess("");
        setIsDataModified(false);

        // Reset fields.
        setUserDataChanges({});

        setCompanyDataChanges({});

        setPasswordDataChanges({});

        setTabIndex(newValue);
      }
    } else {
      setError("");
      setSuccess("");
      setTabIndex(newValue);
    }
  };

  const handleAccountSave = async (e) => {
    e.preventDefault();

    if (!userData) {
      console.warn("userData is undefined. Aborting save operation.");
      return;
    }

    setError("");
    setSuccess("");
    setLoading(true);

    try {
      const result = await updateUserData(userDataChanges);

      if (result.success) {
        setUserDataChanges({});
        setSuccess("Dine ændringer er gemt");
        setIsDataModified(false);
      } else {
        setError("Udfyld alle felter og prøv igen:\n" + result.error);
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Der skete en fejl");
    } finally {
      setLoading(false);
    }
  };

  const handleCompanySave = (e) => {
    if (!companyData) {
      console.warn("userData is undefined. Aborting save operation.");
      return;
    }

    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);

    fetch(
      process.env.REACT_APP_API_URL +
        "/me/company?company_id=" +
        activeCompanyId,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ...companyData, ...companyDataChanges }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.id === companyData.id) {
          setCompanyData(data);
          setCompanyDataChanges({});
          setSuccess("Dine ændringer er gemt");
          setIsDataModified(false);
        } else {
          let errorMessage = "";
          for (const key in data) {
            errorMessage += data[key][0] + "\r\n";
          }
          setError("Udfyld alle felter og prøve igen:\r\n" + errorMessage);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("Der skete en fejl");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();

    if (!userData) {
      console.warn("userData is undefined. Aborting save operation.");
      return;
    }

    setError("");
    setSuccess("");
    setLoading(true);

    if (
      passwordDataChanges.new_password !== passwordDataChanges.confirm_password
    ) {
      setPasswordConfirmError("Adgangskoderne er ikke ens");
      setLoading(false);
      return;
    }

    fetch(process.env.REACT_APP_API_URL + "/me", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        password: passwordDataChanges.password,
        new_password: passwordDataChanges.new_password,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.id === userData?.id) {
          setSuccess("Dine adgangskode er opdateret");
          setIsDataModified(false);
        } else {
          let errorMessage = "";
          for (const key in data) {
            errorMessage += data[key][0] + "\r\n";
          }
          setError(errorMessage);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("Der skete en fejl");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePasswordConfirmChange = (e) => {
    const value = e.target.value;
    if (passwordDataChanges.new_password !== value) {
      setPasswordConfirmError("Adgangskoderne er ikke ens");
    } else {
      setPasswordConfirmError("");
    }

    setPasswordDataChanges((passwordDataChanges) => ({
      ...passwordDataChanges,
      confirm_password: e.target.value,
    }));
  };

  const handlePartialDbSync = () => {
    if (authenticationIsLoading) return;
    if (!isAuthenticated) return;

    setIsPartialDbSyncing(true);

    fetch(process.env.REACT_APP_API_URL + "/admin/partial-db-sync", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Partial DB sync successful:", data);
        showSnackbar(data.message, "info");
      })
      .catch((error) => {
        console.error("Error:", error);
        showSnackbar(error, "error");
      })
      .finally(() => {
        setIsPartialDbSyncing(false); // Stop syncing
      });
  };

  const handleCustomerPortal = async () => {
    setPortalLoading(true);
    try {
      // Get current URL as return URL
      const returnUrl = window.location.origin + window.location.pathname;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/stripe/customer-portal-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            return_url: returnUrl,
            company_id: activeCompanyId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to get portal URL");
      }

      const data = await response.json();
      window.location.href = data.url;
    } catch (error) {
      console.error("Error creating portal session:", error);
    } finally {
      setPortalLoading(false);
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 3 }}>
        Indstillinger
      </Typography>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="settings tabs"
        variant="scrollable"
        sx={{
          mb: 2,
          overflowX: "auto",
          "& .MuiTabs-flexContainer": {
            flexWrap: "nowrap",
          },
        }}
      >
        {visibleTabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>
      {visibleTabs[tabIndex]?.label === "Profil" && (
        <form onSubmit={handleAccountSave}>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={
                userDataChanges.email ? userDataChanges.email : userData?.email
              }
              disabled
            />
          </Box>
          <Box mb={2}>
            <TextField
              required
              fullWidth
              label="Name"
              name="name"
              value={
                userDataChanges.name ? userDataChanges.name : userData?.name
              }
              onChange={handleChange}
              disabled={loading}
            />
          </Box>
          <Box mb={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Gem ændringer
            </Button>
          </Box>
        </form>
      )}
      {visibleTabs[tabIndex]?.label === "Virksomhed" && (
        <>
          {activeCompanyId ? (
            <>
              <Box
                component="form"
                onSubmit={handleCompanySave}
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <Box>
                  <TextField
                    fullWidth
                    label="CVR nummer"
                    name="vatNumber"
                    value={
                      companyDataChanges.vat_number
                        ? companyDataChanges?.vat_number
                        : companyData?.vat_number
                    }
                    disabled
                  />
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    label="Virksomhedsnavn"
                    name="companyName"
                    value={
                      companyDataChanges.vat_number
                        ? companyDataChanges.name
                        : companyData?.name
                    }
                    disabled
                  />
                </Box>
                {/* <Box mb={2}>
            <TextField
              fullWidth
              label="Virksomheds email"
              name="email"
              value={companyData.email}
              onChange={handleChange}
              disabled={loading}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Hoved telefon nummer"
              name="phone"
              value={companyData.phone}
              onChange={handleChange}
              disabled={loading}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Hjemmeside"
              name="website"
              value={companyData.website}
              onChange={handleChange}
              disabled={loading}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Logo"
              name="logo"
              value={companyData.logo}
              onChange={handleChange}
              disabled={loading}
            />
          </Box> */}
                <Box>
                  <FormControl fullWidth>
                    <InputLabel id="bank-label">Bank *</InputLabel>
                    <Select
                      required
                      labelId="bank-label"
                      id="bank"
                      label="Bank"
                      name="bank_vendor_id"
                      value={
                        companyDataChanges.bank_vendor_id
                          ? companyDataChanges.bank_vendor_id
                          : companyData.bank_vendor_id
                      }
                      onChange={handleChange}
                      disabled={loading}
                    >
                      {banks.map((bank) => (
                        <MenuItem key={bank.id} value={bank.id}>
                          {bank.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                  >
                    Gem ændringer
                  </Button>
                </Box>
              </Box>

              <CompanyMemberTable />
            </>
          ) : (
            <Box sx={{ textAlign: "center", padding: 2 }}>
              <Typography variant="h6" color="textSecondary">
                Ingen aktiv virksomhed valgt.
              </Typography>
              <Typography variant="body1">
                Vælg en virksomhed for at redigere detaljer eller se
                informationer.
              </Typography>
            </Box>
          )}
        </>
      )}
      {visibleTabs[tabIndex]?.label === "Adgangskode" && (
        <form onSubmit={handlePasswordChange}>
          <Box mb={2}>
            <TextField
              fullWidth
              required
              label="Din adgangskode"
              type={showCurrentPassword ? "text" : "password"}
              name="password"
              value={passwordDataChanges?.password || ""}
              onChange={handleChange}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setCurrentShowPassword(!showCurrentPassword)
                      }
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              required
              label="Ny adgangskode"
              type={showPassword ? "text" : "password"}
              name="new_password"
              autoComplete="new-password"
              helperText="Adgangskoden skal være mindst 8 tegn lang"
              value={passwordDataChanges?.new_password || ""}
              onChange={handleChange}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              required
              label="Bekræft adgangskode"
              type={showConfirmPassword ? "text" : "password"}
              name="confirm_password"
              autoComplete="new-password"
              onChange={handlePasswordConfirmChange}
              error={!!passwordConfirmError}
              helperText={passwordConfirmError}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mb={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Opdater adgangskode
            </Button>
          </Box>
        </form>
      )}
      {visibleTabs[tabIndex]?.label === "Fakturering & Abonnement" && (
        <>
          {activeCompanyId ? (
            <Box mb={12}>
              <Stack spacing={2}>
                <Alert
                  severity="info"
                  sx={{
                    mt: 2,
                    backgroundColor: (theme) => theme.palette.secondary.light,
                    "& .MuiAlert-icon": {
                      color: (theme) => theme.palette.secondary.contrastText,
                    },
                    color: (theme) => theme.palette.secondary.contrastText,
                  }}
                >
                  Du kan administrere din betalingsmetode, se fakturaer og ændre
                  dit abonnement via Stripe betalingsportalen.
                </Alert>

                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<AccountBalanceWallet />}
                    onClick={handleCustomerPortal}
                    disabled={portalLoading}
                  >
                    {portalLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Administrer abonnement"
                    )}
                  </Button>
                </Box>
              </Stack>
            </Box>
          ) : (
            <Box sx={{ textAlign: "center", padding: 2 }}>
              <Typography variant="h6" color="textSecondary">
                Ingen aktiv virksomhed valgt.
              </Typography>
              <Typography variant="body1">
                Vælg en virksomhed for at redigere detaljer eller se
                informationer.
              </Typography>
            </Box>
          )}
        </>
      )}
      {visibleTabs[tabIndex]?.label === "Demo konto" && (
        <Box mb={12}>
          <TextField
            fullWidth
            label="Opret demo via. linket"
            name="demo link"
            value={
              companyData?.demo_token
                ? `${window.location.origin}/opret?demo-token=${companyData.demo_token}`
                : "Du har adgang til at oprette en demokonto, men der blev ikke fundet noget link. Kontakt venligst supporten."
            }
            inputProps={{
              readOnly: true,
            }}
          />
        </Box>
      )}
      {visibleTabs[tabIndex]?.label === "System administration" && (
        <Box mb={12}>
          <Stack spacing={2}>
            <Alert
              severity="info"
              sx={{
                mt: 2,
                backgroundColor: (theme) => theme.palette.secondary.light,
                "& .MuiAlert-icon": {
                  color: (theme) => theme.palette.secondary.contrastText,
                },
                color: (theme) => theme.palette.secondary.contrastText,
              }}
            >
              På denne side kan du udføre systemprocesser afhængigt af dine
              tilladelser.
            </Alert>

            {(environment === "test" || environment === "development") &&
              hasPartialDbSyncPermission && (
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={isPartialDbSyncing}
                    startIcon={<Storage />}
                    onClick={handlePartialDbSync}
                  >
                    Partiel DB sync
                  </Button>
                </Box>
              )}
          </Stack>
        </Box>
      )}
      {error && (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      )}
      {success && (
        <Typography variant="body2" color="success">
          {success}
        </Typography>
      )}
    </Container>
  );
};

export default Settings;
